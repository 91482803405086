<template>
    <div class="kadam">
        <div class="top-bar">
            <el-button @click="state='NewKadam'" type="success" style="height:100%;">הוסף קד"מ חדש</el-button>
            <el-button  @click="state='KadamManage'" type="success" style="height:100%; margin-right:5px; margin-left:0;">ניהול קדמי"ם</el-button>
            
        </div>
        <div class="kadam-content">
            <component @close="state='KadamManage'" @after_new_record="state='KadamManage'" :is="state"/>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import NewKadam from './Kadam/NewKadam.vue'
import KadamManage from './Kadam/KadamManage.vue'


export default {
components:{NewKadam,KadamManage},
setup(){
    const state = ref('KadamManage')
    return{
        state,
    }
}
}
</script>

<style scoped>
.kadam{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.top-bar{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
}
.kadam-content{
    width: 100%;
    height: calc(100% - 50px);
}

</style>